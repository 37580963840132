import { Role } from "src/components/AuthGuard/utils";
import { useLoginInfo } from "src/queries/emil/account";
import { PortalType, TargetGroup } from "./ssr/utils";

// * These lists have to be hard coded until we introduce another property in the targetGroup CMS model
// Target groups that are only used for internal purposes like generic page headers.
export const isInternalOnlyTargetGroup = (targetGroup: TargetGroup) =>
	targetGroup && ["experts"].includes(targetGroup.slug);

export const isHiddenProduct = (
	productSlug: string,
	loginInfo: ReturnType<typeof useLoginInfo>,
	rolePermission: Role | null,
	portal: PortalType | null,
) => {
	const restrictedSlugs = ["iak-police"];

	// are we a broker && in the broker portal or a broker && on a broker only page?
	const isBrokerPage =
		(loginInfo?.isBroker === true && portal === "broker-portal") ||
		(loginInfo?.isBroker === true && rolePermission === "user");

	// if the product slug is iak-police & we're not a broker on a broker page, filter this product
	return restrictedSlugs.includes(productSlug) && !isBrokerPage;
};

// 🔬 Skip
