import {
	chakra,
	ChakraProps,
	PlacementWithLogical,
	Popover,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverTrigger,
	useBoolean,
	useBreakpointValue,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useTranslatedString } from "src/i18n/hooks";
import { Info } from "src/icons";

type InfoTooltipProps = ChakraProps & {
	text: ReactNode;
	placement?: { base: PlacementWithLogical; md: PlacementWithLogical };
	variant?: string;
};

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
	text,
	placement: customPlacement = {
		base: "auto",
		md: "left",
	},
	variant,
	...props
}) => {
	const styles = useMultiStyleConfig("InfoTooltip", {
		variant,
	});
	const t = useTranslatedString();
	const [isOpen, { on: onOpen, off: onClose }] = useBoolean();

	const placement = useBreakpointValue<PlacementWithLogical>(customPlacement);

	return (
		<Popover placement={placement} onOpen={onOpen} onClose={onClose}>
			<PopoverTrigger>
				<chakra.button
					__css={styles.button}
					aria-label={t("infoTooltip.label")}
					data-is-open={isOpen}
					type="button"
					{...props}
				>
					<Info />
				</chakra.button>
			</PopoverTrigger>
			<PopoverContent sx={styles.content}>
				<PopoverCloseButton sx={styles.closeButton} />
				<PopoverBody sx={styles.body}>{text}</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

// 🔬 TBD: RTL?
