import {
	Box,
	chakra,
	ChakraProps,
	Container,
	Flex,
	Link,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { ContactInfoFragment } from "src/__generated__/cms-schema.codegen";
import { DatoAsset, DatoAssetData } from "src/components/Dato/DatoAsset";
import { Hx } from "src/components/Heading/Heading";
import { useTranslatedString } from "src/i18n/hooks";
import { phoneNumberDisplayToTel } from "src/lib/utils";

type ContactProps = {
	title: string;
	subtitle: string;
	contact: ContactInfoFragment;
	children: React.ReactNode;
	photo: DatoAssetData | null;
};

export const ContactPhoto: React.FC<
	{
		name: string;
		photo: NonNullable<ContactProps["photo"]>;
	} & ChakraProps
> = ({ name, photo, ...rest }) => {
	const t = useTranslatedString();

	return (
		<chakra.div>
			<chakra.div {...rest}>
				<DatoAsset
					data={photo}
					alt={t("customer.altText", {
						name,
					})}
					sx={{
						height: "full",
						img: {
							objectFit: "cover",
						},
					}}
				/>
			</chakra.div>
		</chakra.div>
	);
};

export const Contact: React.FC<ContactProps> = ({
	title,
	subtitle,
	contact,
	children,
	photo,
}) => {
	const styles = useMultiStyleConfig("Contact");

	const { name, availability } = contact;

	return (
		<chakra.div __css={styles.wrapper}>
			<Container maxW="container.contactSection">
				<chakra.div sx={styles.contactText}>
					<Flex gap={8}>
						<chakra.div sx={styles.contactDetails}>
							<Hx size="h2" sx={styles.title}>
								{title}
							</Hx>
							<Hx size="h4" as="p" sx={styles.contactDetailText}>
								{subtitle.replace("{name}", name)}
							</Hx>

							{photo && (
								<ContactPhoto
									name={name}
									photo={photo}
									sx={styles.photoMobile}
								/>
							)}

							<chakra.p
								sx={styles.contactDetailText}
								fontSize={{ base: "sm", md: "md" }}
								fontWeight="medium"
								paddingTop={5}
							>
								{contact.text
									? contact.text
											.replace("{name}", name)
											.replace(
												"{availability}",
												availability,
											)
									: null}
							</chakra.p>
						</chakra.div>
						{photo && (
							<ContactPhoto
								name={name}
								photo={photo}
								sx={styles.photoDesktop}
							/>
						)}
					</Flex>
				</chakra.div>
				<chakra.div sx={styles.phoneNumberWrapper}>
					<Link
						id="click_phone_number"
						sx={styles.phoneNumber}
						href={`tel:${phoneNumberDisplayToTel(
							contact.phoneNumber,
						)}`}
					>
						{contact.phoneNumber}
					</Link>
				</chakra.div>
				{children}
			</Container>
		</chakra.div>
	);
};

export const ContactFormsWrapper: React.FC<{
	leftForm?: ReactNode;
	rightForm?: ReactNode;
}> = ({ leftForm, rightForm }) => {
	const styles = useMultiStyleConfig("Contact");

	return (
		<Flex sx={styles.contactForms}>
			<Box
				w="100%"
				boxShadow={
					leftForm
						? "-10px 10px 60px 0px var(--chakra-colors-shadows-drop)"
						: "unset"
				}
			>
				{leftForm}
			</Box>
			<Box w="100%" boxShadow={rightForm ? "dropWide" : "unset"}>
				{rightForm}
			</Box>
		</Flex>
	);
};

// 🔬 Story
