// We need to import these here to re-export
/* eslint-disable no-restricted-imports */
import {
	FormControl as ChakraFormControl,
	FormLabel as ChakraFormLabel,
	FormLabelProps,
	FormControlProps,
	useFormControlContext,
	Input,
	chakra,
	InputGroup,
	InputLeftElement,
	Button,
	InputRightElement,
	CloseButton,
	Divider,
	HStack,
	VStack,
	Radio,
	RadioGroup as ChakraRadioGroup,
	ChakraProps,
} from "@chakra-ui/react";
import { useCombobox } from "downshift";
import React, { ReactNode } from "react";
import {
	useFormContext,
	Controller,
	Control,
	FieldValues,
	Path,
} from "react-hook-form";
import { useTranslatedString } from "src/i18n/hooks";
import { Checkmark, MagnifyingGlass } from "src/icons";
import { TypeOf, ZodTypeAny } from "zod";
import { FormErrorMessageI18n } from "./FormErrorMessageI18n/FormErrorMessageI18n";
import { Hx } from "./Heading/Heading";

// Re-exporting here in case we want to add more checks later
export const FormControl: React.FC<
	FormControlProps & { isInvalid: boolean }
> = ({ ...props }) => <ChakraFormControl {...props} />;

export const FormLabel: React.FC<
	Exclude<FormLabelProps, "children"> & { children: ReactNode }
> = ({ children, ...props }) => {
	const ctx = useFormControlContext();

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (!ctx) {
		throw Error("FormLabel used outside of FormControl context");
	}

	return <ChakraFormLabel {...props}>{children}</ChakraFormLabel>;
};

export const FormHeading = ({
	type,
	children,
	icon,
	isFilled,
	...props
}: {
	type: "small" | "big";
	children: React.ReactNode;
	icon?: React.ReactNode;
	isFilled?: boolean;
} & ChakraProps) => {
	switch (type) {
		case "big":
			return (
				<VStack gap={6} width="full" mb={6} paddingTop={24} {...props}>
					<HStack flexDir="row" width="full" justifyContent="start">
						{icon}
						{isFilled ? (
							<Checkmark color="brand.highlight" w={6} h={6} />
						) : null}
						<Hx margin="auto" size="h4" maxW="container.xs">
							{children}
						</Hx>
					</HStack>
					<Divider
						borderColor={isFilled ? "brand.highlight" : undefined}
					/>
				</VStack>
			);
		case "small":
			return (
				<VStack width="full" gap={2} mb={4} {...props}>
					<Hx size="h5" alignSelf="start">
						{children}
					</Hx>
					<Divider />
				</VStack>
			);
		default:
			return null;
	}
};

type ComboboxProps = {
	formSchema: ZodTypeAny;
	data: Array<string>;
	placeholder: string;
};

export const Combobox: React.FC<ComboboxProps> = ({
	formSchema,
	data,
	placeholder,
}) => {
	const ctx = useFormControlContext();

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (!ctx) {
		throw Error("Combobox used outside of FormControl context");
	}

	const t = useTranslatedString();

	const { control, setValue } = useFormContext<TypeOf<typeof formSchema>>();

	const [items, setItems] = React.useState(data);

	const { isOpen, getMenuProps, getInputProps, getItemProps, setInputValue } =
		useCombobox({
			onInputValueChange: ({ inputValue }) => {
				if (!inputValue) {
					setValue("searchTerm", "");
					setItems(data);

					return;
				}

				setValue("searchTerm", inputValue);
				setItems(
					data.filter((item) =>
						item.toLowerCase().includes(inputValue.toLowerCase()),
					),
				);
			},
			items,
		});

	return (
		<>
			<Controller
				control={control}
				name="searchTerm"
				render={({ field }) => (
					<InputGroup>
						<InputLeftElement color="brand.medium">
							<MagnifyingGlass />
						</InputLeftElement>
						<Input
							{...field}
							placeholder={placeholder}
							{...getInputProps()}
							value={field.value}
						/>
						<InputRightElement color="brand.medium">
							<CloseButton
								aria-label={t("viewClaim.search.delete")}
								sx={{
									_hover: { bg: "none", color: "brand.blue" },
								}}
								onClick={() => {
									setValue("searchTerm", "");
									setInputValue("");
								}}
							/>
						</InputRightElement>
					</InputGroup>
				)}
			/>
			<chakra.ul
				__css={{
					maxH: "32rem",
					overflowY: "scroll",
					paddingLeft: 0,
					boxShadow: "drop",
					border: "1px solid",
					color: "brand.light",
					marginBottom: 4,
					borderRadius: "md",
					visibility: isOpen && items.length ? undefined : "hidden",
				}}
				{...getMenuProps()}
			>
				{isOpen &&
					items.map((item) => {
						return (
							<Button
								variant="outline"
								type="button"
								sx={{
									fontWeight: "normal",
									w: "full",
									border: "none",
									borderRadius: "none",
									_hover: {
										bg: "brand.light",
									},
									color: "brand.blue",
									justifyContent: "flex-start",
									pl: 10,
								}}
								key={item}
								{...getItemProps({ item })}
							>
								{item}
							</Button>
						);
					})}
			</chakra.ul>
			{items.length < 1 && <>{t("viewClaim.search.numberNotFound")}</>}
		</>
	);
};

export const RadioGroup: React.FC<{
	value: string;
	values: Readonly<Array<string>>;
	labels: Array<string>;
	onChange: (v: unknown) => void;
}> = ({ value, values, labels, onChange }) => {
	const ctx = useFormControlContext();

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (!ctx) {
		throw Error("RadioGroup used outside of FormControl context");
	}

	return (
		<ChakraRadioGroup
			onChange={onChange}
			variant="tabs"
			value={value}
			as={HStack}
			placeContent="center"
			gap={24}
			marginTop={8}
			marginBottom={8}
		>
			{labels.map((label, index) => {
				const value = values[index];

				return (
					<Radio key={value} value={value}>
						{label}
					</Radio>
				);
			})}
		</ChakraRadioGroup>
	);
};

type TextFormProps<T extends FieldValues> = {
	control: Control<T>;
	name: Path<T>;
	isInvalid: boolean;
	label: ReactNode;
	placeholder: string;
	error?: { message?: string };
	children?: ReactNode;
};

export const TextForm = <T extends FieldValues>({
	control,
	name,
	isInvalid,
	label,
	placeholder,
	error,
	children,
}: TextFormProps<T>): React.ReactNode => {
	return (
		<FormControl isInvalid={isInvalid}>
			<FormLabel variant="medium">{label}</FormLabel>
			<Controller
				control={control}
				name={name}
				render={({ field }) => {
					return (
						<>
							<Input
								variant="outline"
								borderColor="brand.light"
								placeholder={placeholder}
								{...field}
							/>
							{children}
						</>
					);
				}}
			/>
			<FormErrorMessageI18n error={error} />
		</FormControl>
	);
};

// 🔬 TBD: Please evaluate
