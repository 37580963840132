import ky from "ky";
import { tenantSlug } from "src/lib/emil/utils";
import { trimSlashes } from "src/lib/utils";
import { AnyRecord } from "src/types";
import { getEmilEnv } from "./utils";

export const postToPublicApi = async <
	Data extends AnyRecord = AnyRecord,
	Body extends AnyRecord = AnyRecord,
>(
	endpoint: string,
	json: Body,
) => {
	const req = await ky.post(
		`${[getEmilEnv(), "publicapi/v1", endpoint]
			.map(trimSlashes)
			.join("/")}`,
		{
			json,
			headers: {
				authorization: `Bearer ${tenantSlug}`,
			},
		},
	);

	return req.json<Data>();
};

// 🔬 TBD: e2e
